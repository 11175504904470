import React, { useEffect, useState } from 'react';
import './App.css'
const App = () => {
  const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    let dest = new Date('Feb 20, 2025 23:59:59').getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      let diff = dest - now;

      if (diff <= 0) {
        const nextMonthDate = new Date();
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

        if (nextMonthDate.getMonth() === 0) {
          nextMonthDate.setFullYear(nextMonthDate.getFullYear() + 1);
        }

        dest = nextMonthDate.getTime();
        diff = dest - now;
      }

      const days = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(2, '0');
      const hours = String(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(2, '0');

      setTimer({ days, hours, minutes, seconds });
    };

    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="relative">
      <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
        <div className="w-full flex-col justify-center items-center inline-flex">
          <a href="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="170" height="44" viewBox="0 0 170 44" fill="none">
              {/* SVG content omitted for brevity */}
            </svg>
          </a>
          <div className="w-full flex-col justify-center items-center gap-5 flex">
            <div className="w-full flex-col justify-center items-center gap-6 flex">
              <div className="w-full flex-col justify-start items-center gap-2.5 flex">
                <h1 className='text-5xl font-bold'>Imdad<span className='font-light'>Energy</span></h1>
                <h2 className="text-center text-gray-800 text-3xl font-bold font-manrope leading-normal">
                  Please bear with us! We're currently under Developement.
                </h2>
                <p className="text-center text-gray-500 text-base font-normal leading-relaxed">
                  It's going to take some time to fix the error. We'll be back online in.
                </p>
              </div>
              <div className="flex items-start justify-center w-full gap-1 count-down-main">
                {['days', 'hours', 'minutes', 'seconds'].map((unit, index) => (
                  <React.Fragment key={unit}>
                    {index > 0 && (
                      <h3 className="text-center text-black text-3xl font-normal font-manrope leading-normal">:</h3>
                    )}
                    <div className="timer">
                      <div className="">
                        <h3 className={`countdown-element ${unit} text-center text-black text-3xl font-normal font-manrope leading-normal`}>
                          {timer[unit]}
                        </h3>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className="w-full justify-center items-center gap-2.5 flex sm:flex-row flex-col">
                <input
                  type="text"
                  className="sm:w-80 w-full h-10 focus:outline-none text-gray-900 placeholder-gray-400 text-sm font-normal leading-relaxed px-3.5 py-2 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-300"
                  placeholder="Your mail"
                />
                <button className="sm:w-fit w-full px-3.5 py-2 bg-indigo-700 hover:bg-indigo-800 transition-all duration-700 ease-in-out rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
                  <span className="px-1.5 text-white text-sm font-medium leading-6 whitespace-nowrap">Notify Me</span>
                </button>
              </div>
            </div>
            <img
              src="https://pagedone.io/asset/uploads/1718004199.png"
              alt="under maintenance"
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;
